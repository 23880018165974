import React, { useEffect, useState } from "react";
// import navbarlogo from "../../assets/images/navbarlogo.png";
import image from "../../assets/images/logo (1).png";
import {
  NavLink,
  useLocation,
  useNavigate,
  useHistory,
} from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import { FiAlignRight } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosInstance from "../../api/url";
import { fetchNavbarData } from "../../api/API";
import NavbarLoader from "../../common/NavbarLoader/NavbarLoader";
import "./Navbar.css";
import ImageComponentNavBar from "../Home/ImageComponentNavBar";
import Header from "../Header/Header";

function Navbar() {
  const [activeNavLink, setActiveNavLink] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [siteName, setSiteName] = useState();
  const [donation, setdonation] = useState();
  const [publicationList, setPublicationList] = useState();
  const [activityId, setActivityId] = useState();
  const [navActivityIds, setNavActivityIds] = useState([]);
  const [navbarLogo, setNavbarLogo] = useState();
  const [isNavbarLogoLoaded, setIsNavbarLogoLoaded] = useState(false);
  const [pages, setPages] = useState();
  const [site, setSite] = useState({});

  const navigate = useNavigate();

  const handleClickDonation = () => {
    if (site.donation_site === "own_website") {
      navigate("/donation");
    } else if (
      site.donation_site === "other_website" &&
      site.donation_page_url
    ) {
      window.open(site.donation_page_url, "_blank");
    }
  };

  useEffect(() => {
    console.log("activityId", activityId);
  }, [activityId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchNavbarData({
          url: process.env.REACT_APP_API_URL,
        });
        if (response.data.status == true) {
          setApiData(response.data.responseBody.nav_activity);
          setSiteName(response.data.responseBody.site.name);
          localStorage.setItem(
            "siteName",
            response.data.responseBody.site.name
          );
          setdonation(response.data.responseBody.site.donation);
          setPublicationList(response.data.responseBody.nav_publication);
          setNavbarLogo(response.data.responseBody.site.logo);
          setSite(response.data.responseBody.site);
          console.log("logo", response.data.responseBody.site.logo);
          setIsNavbarLogoLoaded(true);
          console.log(
            "response.data.responseBody.nav_publication",
            response.data.responseBody?.nav_publication
          );
          setActivityId(response?.data?.responseBody?.nav_activity);
          setPages(response?.data?.responseBody?.pages);
          console.log(
            "activityIdactivityId",
            response?.data?.responseBody?.nav_activity
          );
          console.log(
            "response.data.responseBody.site",
            response.data.responseBody.site
          );
          // console.log(
          //   "responsenavbar",
          //   response.data.responseBody.nav_activity[0].id
          // );
          // console.log(
          //   "responsenavbarPublication",
          //   response.data.responseBody.nav_publication
          // );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("apiData", apiData);
  }, [apiData]);

  const location = useLocation();

  const containsActivity = (name) => {
    return location.pathname.includes(name);
  };

  useEffect(() => {
    const pathName = location.pathname;
    setActiveNavLink(pathName === "/audio-list");
    console.log("activeNavLink", activeNavLink);
  }, [location]);

  return (
    <>
      <Header
        donation={donation}
        handleClickDonation={handleClickDonation}
        pages={pages}
      />
      <nav class="navbar">
        <div class="container-fluid">
          <div className="navbar-right-wrap">
            <NavLink className="navbar-brand" to="/">
              {/* {!isNavbarLogoLoaded && <NavbarLoader />} */}
              <ImageComponentNavBar
                src={navbarLogo}
                className="navbar-logo"
                // onLoad={() => setIsNavbarLogoLoaded(true)}
              />
            </NavLink>
            <div className="nav-text">
              {/* <h6>Brahmanand SanskarDham</h6> */}
              {/* <h6>{siteName}</h6> */}
            </div>
          </div>
          <button
            class="navbar-toggler d-xl-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <FiAlignRight className="toggler-iscon" />
          </button>

          <div className="navbar-wrap">
            <div className="navbar-text1 nav-elements">
              <ul className="nav-link-wrap">
                <li className="nav-item active">
                  <NavLink to="/" className="nav-link">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className={`nav-link ${
                      containsActivity("activities") ? "active" : "no-active"
                    }`}
                    to={`/activities`}
                  >
                    Activities
                    <HiPlus className="nav-icon" />
                  </NavLink>

                  <ul className="dropdown-menu">
                    {Object.values(apiData).map((item) => (
                      <li key={item.id}>
                        <NavLink
                          className="dropdown-item"
                          to={`/activities/${item.id}/${item.name}`}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <NavLink
                    className={`nav-link ${
                      containsActivity("publication-detail")
                        ? "active"
                        : "no-active"
                    }`}
                    to={
                      publicationList &&
                      `/publication-detail/${publicationList[0].id}/${publicationList[0].name}`
                    }
                  >
                    Publications
                    <HiPlus className="nav-icon" />
                  </NavLink>

                  <ul className="dropdown-menu">
                    {publicationList &&
                      publicationList.length > 0 &&
                      publicationList.map((item) => (
                        <li key={item.id}>
                          <NavLink
                            className="dropdown-item"
                            to={`/publication-detail/${
                              item.id
                            }/${item.name.toLowerCase()}`}
                          >
                            {item.name}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </li>
                <li className="nav-item active">
                  <NavLink to="/branches" className="nav-link">
                    Branches
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className={`nav-link ${activeNavLink ? "active" : ""}`}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact-us" className="nav-link">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          {/* ===============  offcanvas =============== */}

          <div
            class="offcanvas offcanvas-start"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                <img src={image} alt="" className="navbar-logo" />
              </h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body nav-elements">
              <ul class="navbar-nav">
                <li className="nav-item active">
                  <NavLink
                    to="/"
                    className="nav-link"
                    activeClassName="active-link"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="/activities"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    Activities <HiPlus className="nav-icon" />
                  </a>

                  <ul className="dropdown-menu">
                    {Object.values(apiData).map((item) => (
                      <li key={item.id}>
                        <a
                          className="dropdown-item"
                          href={`/activities/${item.id}/${item.name}`}
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    to="/publication-detail"
                    className="nav-link"
                    activeClassName="active-link"
                    data-bs-toggle="dropdown"
                  >
                    Publications
                    <HiPlus className="nav-icon" />
                  </NavLink>

                  <ul className="dropdown-menu">
                    {publicationList &&
                      publicationList.length > 0 &&
                      publicationList.map((item) => (
                        <li key={item.id}>
                          <a
                            className="dropdown-item"
                            href={`/publication-detail/${
                              item.id
                            }/${item.name.toLowerCase()}`}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/branches"
                    className="nav-link"
                    activeClassName="active-link"
                  >
                    Branches
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className="nav-link"
                    activeClassName="active-link"
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact-us">
                    {" "}
                    Contact Us{" "}
                  </a>
                </li>
                {donation === "1" ? (
                  <a class="nav-link donation responsive" href="#">
                    Donate Now
                  </a>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
