import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FaSearchPlus } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import imageNotFound from "../../src/assets/images/NoImageFound.jpg.png";
import { useLocation } from "react-router-dom";

const Images = ({ publicatioAlbumnData }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleDownload = (publication) => {
    const media = JSON.parse(publication.media);

    media.forEach((mediaUrl) => {
      fetch(mediaUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "";
          anchor.target = "_blank";
          anchor.click();

          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading media:", error);
        });
    });
  };

  console.log("walpaperimage", publicatioAlbumnData);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const is_open_wallpaper = params.get("is_open_wallpaper");
    console.log("is_open_wallpaper", is_open_wallpaper);
    if (is_open_wallpaper && publicatioAlbumnData) {
      console.log("idOfpdf", is_open_wallpaper);
      const index = publicatioAlbumnData.findIndex(
        (item) => item.id.toString() === is_open_wallpaper
      );
      console.log("is_open_wallpaper", is_open_wallpaper);
      if (index !== -1) {
        // handleView(
        //   JSON.parse(book.media)[0],
        //   book.name,
        //   book.short_description,
        //   book.publications_id
        // );
        setPhotoIndex(index);
        setIsOpen(true);

        // Parse the current search parameters from the location
        const searchParams = new URLSearchParams(location.search);

        // Remove the 'is_open_wallpaper' parameter
        searchParams.delete("is_open_wallpaper");

        // Construct the new search string
        const newSearch = searchParams.toString();

        // Construct the new pathname with the updated search parameters
        const newPathname = location.pathname;

        // Combine the new pathname and search string to create the new URL
        const newUrl = `${newPathname}${newSearch ? "?" + newSearch : ""}`;

        // Navigate to the new URL
        //  history.push();

        window.history.pushState(null, "", newUrl);
      }
    }
  });

  return (
    <div className="pt-4">
      <Grid container spacing={2}>
        {Array.isArray(publicatioAlbumnData) &&
          publicatioAlbumnData.length > 0 &&
          publicatioAlbumnData.map((item, index) => {
            const mediaArray = item.media ? JSON.parse(item.media) : null;
            const thumbMediaArray = item.thumb_media
              ? JSON.parse(item.thumb_media)
              : null;
            return (
              <>
                <Grid item xs={6} sm={6} md={3} key={index}>
                  <div key={index} className="wallpaper-content">
                    <div
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    >
                      <img
                        alt=""
                        src={thumbMediaArray ? thumbMediaArray : imageNotFound}
                        className="wallpaper-img"
                      />
                      <div className="search-icon">
                        <FaSearchPlus />
                      </div>
                    </div>

                    <div className="image-details">
                      <h4 className="wallpaper-name">{item.name}</h4>
                    </div>
                    <button onClick={() => handleDownload(item)}>
                      Download
                    </button>
                  </div>
                </Grid>
              </>
            );
          })}
        {isOpen && (
          <Lightbox
            mainSrc={JSON.parse(publicatioAlbumnData[photoIndex].media)}
            nextSrc={
              publicatioAlbumnData[
                (photoIndex + 1) % publicatioAlbumnData.length
              ].media
            }
            prevSrc={
              publicatioAlbumnData[
                (photoIndex + publicatioAlbumnData.length - 1) %
                  publicatioAlbumnData.length
              ].media
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + publicatioAlbumnData.length - 1) %
                  publicatioAlbumnData.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % publicatioAlbumnData.length)
            }
          />
        )}
      </Grid>
    </div>
  );
};

export default Images;
