import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import AudioAlbumList from "./AudioAlbumList";
import AudioListPlayer from "./AudioListPlayer";

const AudioAndAlbumList = ({ albumnData, isAlbum }) => {
  console.log("AUDIO LIST ", albumnData, isAlbum);
  if (isAlbum === true) {
    return <AudioAlbumList albumnData={albumnData} />;
  } else {
    return <AudioListPlayer audioListData={albumnData} />;
  }
};

export default AudioAndAlbumList;
