import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";

const VideoAlbumList = ({ albumnData }) => {
  const navigate = useNavigate();
  const params = useParams();

  const handleClick = (album_id, pub_id) => {
    console.log("Clicked on Album with album id:", album_id);
    navigate(`/video-album/${album_id}/publication/${pub_id}`);
  };

  console.log("video params", params);

  console.log("albumnData", albumnData);
  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        className="katha-content-wrap"
      >
        {albumnData &&
          albumnData.map((album, index) => (
            <Grid key={index} item xs={6} sm={6} md={3}>
              <div className="katha-img-wrap">
                <img src={album.image} alt="" className="video-album-img" />
                <div className="katha-content">
                  <div>
                    <h4>{album.name ? album.name : album.title}</h4>
                  </div>
                  <div>
                    <p>{album.short_description}</p>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        handleClick(album.id, album.publication_id)
                      }
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default VideoAlbumList;
