import React, { useEffect, useState } from "react";
import image from "../../../assets/images/subheader.jpg";
import image1 from "../../../assets/images/child_development_activities.jpg";
import image2 from "../../../assets/images/community_activities.jpg";
import image3 from "../../../assets/images/education_activities.jpg";
import image4 from "../../../assets/images/enviormnetal_activities.jpg";
import image5 from "../../../assets/images/medical_activities.jpg";
import image6 from "../../../assets/images/cultural_activities.jpg";
// import image7 from "../../assets/images/date.png";
import image7 from "../../../assets/images/date.png";
import Container from "@mui/material/Container";
import Gallery from "../../LightGallery/Gallery";
import Slider from "react-slick";
import Card from "@mui/material/Card";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { FaArrowRight } from "react-icons/fa6";
import Typography from "@mui/material/Typography";
import CommonBreadcrumbs from "../../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchActivitiyDetails, fetchSlider } from "../../../api/API";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { FaSearchPlus } from "react-icons/fa";
import imageNotFound from "../../../assets/images/NoImageFound.jpg.png";
import dayjs from "dayjs";
import Loader from "../../../common/Loader/Loader";
import InnerpageLoader from "../../Home/InnerpageLoader";
import "./ActivitiesDetails.css";

const ActivitiesDetails = () => {
  const [apiData, setApiData] = useState([]);
  const [activityDetails, setActivityDetails] = useState({
    title: "",
    date: "",
  });
  const { title, description } = useParams();
  const [photoGallery, setPhotoGallery] = useState([]);
  const [activityDate, setActivityDate] = useState(dayjs());
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [sliderLength, setSliderLength] = useState(0);
  const [eventImages, setEventImages] = useState({});
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [thumbPhotoGallery, setThumbPhotoGallery] = useState([]);
  const [activityData, setACtivityData] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const [activityName, setActivityName] = useState([]);

  const params = useParams();
  useEffect(() => {
    console.log("params.activityDetails_id", params.id, params.activity_id);
    fetchData(params.id, params.activity_id);
  }, [params]);

  console.log("title", title);
  console.log("description", description);

  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow_1">
      <GrPrevious size={20} />
    </div>
  );

  const CustomNextvArrow = (props) => (
    <div {...props} className="custom-next-arrow_1">
      <GrNext size={20} />
    </div>
  );

  const [sliderSettings] = useState({
    dots: true,
    infinite: sliderLength >= 4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextvArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Activities",
      url: `/activities`,
    },
    {
      label: apiData?.activity?.name,
      url: `/activities/${apiData?.details?.activity_id}/${apiData?.details?.title}`,
    },
    <label color="text.primary" className="active-link-color">
      {apiData?.details?.title}
    </label>,
  ];

  const fetchData = async (id, activity_id) => {
    try {
      const response = await fetchActivitiyDetails({
        url: process.env.REACT_APP_API_URL,
        page: "activity",
        activity_details_id: id,
        activity_id: activity_id,
      });
      if (response.data.status == true) {
        setApiData(response.data.responseBody);
        console.log("eventImages", response.data.responseBody);
        // setActivityTitle(response.data.responseBody.details.title);
        setActivityDetails({
          title: response.data.responseBody.details.title,
          date: response.data.responseBody.details.activity_date,
        });
        const photosArray = JSON.parse(
          response.data.responseBody.details.photos_gallery
        );
        setPhotoGallery(photosArray);
        console.log(
          "responsePhotoGallery",
          response.data.responseBody.details.photos_gallery
        );
        setThumbnailImage(response.data.responseBody.details.thumbnail_image);
        console.log(
          "thumbnailImage",
          response.data.responseBody.details.thumbnail_image
        );
        setThumbPhotoGallery(
          JSON.parse(response.data.responseBody.details.thumb_photos_gallery)
        );
        console.log(
          "thumbGallery",
          response.data.responseBody.details.thumb_photos_gallery
        );

        // setActivityDate(response.data.responseBody.details.activity_date);
        setActivityDate(
          dayjs(response.data.responseBody.details.activity_date)
        );
        setRelatedEvents(response.data.responseBody.relevent_details);
        console.log(
          "responseEvents",
          response.data.responseBody.relevent_details
        );
        setSliderLength(response.data.responseBody.relevent_details.length);
        setACtivityData(response.data.responseBody.details);
        setActivityName(response.data.responseBody.activity);
        console.log("activityName", response.data.responseBody.activity.name);
        console.log("activityData..", response.data.responseBody.details.name);
        console.log(
          "activityDate",
          response.data.responseBody.details.activity_date
        );
        console.log(
          "responseActivityDetails",
          response.data.responseBody.details.title
        );
        console.log(
          "responseActivityDetails",
          response.data.responseBody.details.date
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  // const formattedDate = activityDate.format("MM/DD/YYYY");

  const handleClick = (id, activity_id) => {
    window.history.pushState(
      null,
      "",
      `/activities-detail/${activity_id}/${id}`
    );
    fetchData(id, activity_id);
  };

  useEffect(() => {
    console.log("apiData", apiData);
  }, [apiData]);

  useEffect(() => {
    const fetchBanner = async () => {
      setLoading(true);
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "activity",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const getThumbnailImage = (index) => {
    if (thumbPhotoGallery) {
      console.log("thumbPhotoGallerythumbPhotoGallery", thumbPhotoGallery);
      if (Array.isArray(thumbPhotoGallery) && thumbPhotoGallery.length >= 0) {
        return thumbPhotoGallery[index];
      }
    }
    return imageNotFound;
  };

  const date = new Date(activityData.activity_date);
  const options = { day: "numeric", month: "short" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const day = date.getDate();
  const month = formattedDate.split(" ")[0];
  console.log("formattedDateActivity", formattedDate);

  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>

      {/* ===================================== */}

      <Container className="sub-title">
        <div>
          <div>
            {Array.isArray(activityData) ? (
              activityData.map((activity, index) => {
                // const date = new Date(activity.activity_date);
                // const options = { day: "numeric", month: "short" };
                // const formattedDate = date.toLocaleDateString("en-US", options);
                // const day = date.getDate();
                // const month = formattedDate.split(" ")[0];
                // console.log("formattedDateActivity", formattedDate);
                return (
                  <>
                    <div key={index}>
                      <div>
                        <img
                          src={activity.image}
                          alt={activity.title}
                          className="activity-details-image"
                        />
                      </div>
                      <h1 className="activities-name">{activity.title}</h1>
                      <div className="activities-decs">
                        {activity.short_description}
                      </div>
                      <p>{day}</p>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="activitydetails-image">
                <img
                  src={activityData.image || activityData.image}
                  alt={activityData.title}
                  className="activity-details-image"
                />
                <h1 className="activities-name">{activityData.title}</h1>
                <div className="activities-decs">
                  {activityData.description}
                </div>
                <div className="activities-date">
                  <div>
                    <h5>{day}</h5>
                  </div>
                  <div>
                    <p>{month}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr></hr>

        {/* ===================================== */}
        <div className="date-picker-wrap">
          <div>
            <img src={image7} className="date-picker-img" />
          </div>
          <div>
            {/* <h5>04 April 2024</h5> */}
            {/* <h5>{date}</h5> */}
            {/* <h5>{formattedDate}</h5> */}
            <h5>{activityDate.format("MMMM DD, YYYY")}</h5>
          </div>
        </div>
        <div className="gallery-title">{/* <h4>{title}</h4> */}</div>

        <Container style={{ margin: "0 auto", display: "contents" }}>
          <div className="pt-0 section-padding">
            <LightGallery
              // onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
            >
              {photoGallery.map((image, index) => (
                <a key={index} href={image} className="wallpaper-content">
                  <img
                    src={getThumbnailImage(index)}
                    // alt={"No Image Found"}
                    className="activities-img-thumbnail"
                    onError={(e) => {
                      e.target.src = imageNotFound;
                    }}
                  />
                  {/* <img alt="" src={thumbnailImage} className="katha-img" /> */}
                  {/* //thumbnailImage[index] */}
                  <div className="search-icon">
                    <FaSearchPlus />
                  </div>
                </a>
              ))}
            </LightGallery>
          </div>
        </Container>
      </Container>
      {/* ===================================== */}

      <Container className="sub-title">
        <h6>Related Event</h6>
        <Slider {...sliderSettings} className="slider-margin">
          {relatedEvents ? (
            relatedEvents.map((event, index) => (
              <div className="slider-item" key={index}>
                <Card
                  className="activities-inner-content-wrap"
                  onClick={() => handleClick(event.id, event.activity_id)}
                >
                  <img
                    src={
                      event
                        ? event.thumbnail_image !== ""
                          ? event.thumbnail_image
                          : imageNotFound
                        : imageNotFound
                    }
                    // alt={"No Image Found"}
                    className="activities-img"
                    onError={(e) => {
                      e.target.src = imageNotFound;
                    }}
                  />

                  <div className="activities-inner-content">
                    <h5>{event.activity_name}</h5>
                    {/* <h5>{event.title}</h5> */}
                    <p>{event.short_description}</p>
                  </div>
                </Card>
              </div>
            ))
          ) : (
            <div className="no-data">No data available</div>
          )}

          {/* =========================== */}
        </Slider>
      </Container>
    </>
  );
};

export default ActivitiesDetails;
