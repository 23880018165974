import React from "react";
import "./SectionTitle.css";

const SectionTitle = ({ title, className }) => {
  const sectionTitleClasses = `section-title ${className} || ''}`;

  return (
    <>
      <h6 className={sectionTitleClasses}>{title}</h6>
    </>
  );
};

export default SectionTitle;
