import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import "./Home.css";
import { fetchSlider } from "../../api/API";
import LazyLoad from "react-lazyload";

const HomeSlider = ({ setIsSliderLoaded }) => {
  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow">
      <GrPrevious className="custom-prev-icon" />
    </div>
  );

  const CustomNextvArrow = (props) => (
    <div {...props} className="custom-next-arrow">
      <GrNext className="custom-next-icon" />
    </div>
  );

  const [sliderSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextvArrow />,
  });

  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchSlider({
        url: process.env.REACT_APP_API_URL,
        page: "home",
      });
      if (response.data.code == 200 || response.data.code == 201) {
        setApiData(response.data.responseBody);
        console.log("response.data.code", response.data.code);
      }
      console.log("responsehome", response.data.responseBody);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid slider-wrap">
      <div className="spinner-container">
        <Slider
          {...sliderSettings}
          // style={{ height: "400px" }}
          className="slider-buttons"
        >
          {apiData.map((image, index) => (
            <div key={index}>
              <div>
                <LazyLoad height={200} offset={100}>
                  <img
                    src={image}
                    alt="Placeholder"
                    style={{ width: "100%", height: "auto" }}
                    data-src={image}
                    className="slider-img"
                  />
                </LazyLoad>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HomeSlider;
