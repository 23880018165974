import React, { useEffect, useState } from "react";
import image from "../../../assets/images/subheader.jpg";
import CommonBreadcrumbs from "../../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
// import ImageComponent from "../../Home/ImageComponent";
import InnerpageLoader from "../../Home/InnerpageLoader";
import { fetchPublicationList, fetchSlider } from "../../../api/API";
import PublicationSearchModal from "../../../common/PublicationSearchModal/PublicationSearchModal";

const Pdf = () => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [publicationData, setPublicationData] = useState(null);
  const [publicatioAlbumnData, setPublicationAlbumData] = useState(null);
  const [isAlbum, setIsAlbum] = useState(false);
  const [list, setList] = useState([]);
  const [publicationId, setPublicationId] = useState();
  const [SelectedPublicationslug, setSelectedPublicationslugn] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [year, setYear] = React.useState("");
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [publicationList, setPublicationList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const { name, publications_id } = location.state || {};

  console.log("publications_id", publications_id);
  console.log("name", name);
  useEffect(() => {
    console.log("paramsPdf", params);

    var pdf = localStorage.getItem(params.pdfName);
    setPdfUrl(pdf);

    console.log("paramssssssss");
  }, []);

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    { label: "Books", url: `/publication-detail/${params.id}/book` },
    <label color="text.primary" className="active-link-color">
      PDF
    </label>,
  ];

  useEffect(() => {
    setLoading(true);
    const fetchBanner = async () => {
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "publication",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    if (params.pub_id) {
      fetchData1(params.pub_id);
      setPublicationId(params.pub_id);
    }
  }, [params.pub_id]);

  const fetchData1 = async (publicationid) => {
    try {
      const response = await fetchPublicationList({
        url: process.env.REACT_APP_API_URL,
        page: "publication",
        order_by: "all",
        publication_id: publicationid,
        // publication_id: params.id,
      });

      if (response?.data?.responseBody.list_publication) {
        setPublicationData(response.data.responseBody.publication.ui_slug);
        // setSelectedPublication(response.data.responseBody.list_publication);
      }
      setSelectedPublicationslugn(
        response.data.responseBody.publication.ui_slug
      );
      console.log(
        "publication------------",
        response.data.responseBody.publication.ui_slug
      );
      if (response.data.responseBody.albums) {
        setIsAlbum(true);
        setPublicationAlbumData(response.data.responseBody.albums);
      }
      // if (response.data.responseBody.list) {
      //   // setIsAlbum(false);
      //   // setPublicationAlbumData(response.data.responseBody.list);
      //   console.log("lislislistttt", response.data.responseBody.list);
      // }
      setList(response.data.responseBody);
      // console.log(
      //   "response.data.responseBody{{{{",
      //   response.data.responseBody.list_publication
      // );
      // console.log(
      //   "response.data.responseBody....",
      //   response.data.responseBody.albums
      // );
      // console.log(
      //   "responsee........_______.......",
      //   response.data.responseBody.list_publication[0].name
      // );
      setPublicationList(response?.data?.responseBody.list_publication);
      console.log("listname", response?.data?.responseBody.list_publication);
      // setApiData(response);
      // console.log(
      //   "response........",
      //   response.data.responseBody.publication.ui_slug
      // );
      // console.log("reponseresponse", response.data.responseBody.publication);
      // console.log(
      //   "responsePublication",
      //   response?.data?.responseBody.list_publication
      // );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleButtonClick = (
    publicationName,
    PublicationId,
    index,
    ui_slug,
    publicationData,
    isActive
  ) => {
    setPublicationAlbumData(null);
    setSelectedPublicationslugn(ui_slug);
    setActiveTab(index);
    setIndicatorPosition(index);
    fetchData1(PublicationId);
    setActiveStatus(isActive);

    // window.history.pushState(
    //   null,
    //   "",
    //   `/publication-detail/${PublicationId}/${publicationName}`
    // );
    setPublicationId(PublicationId);

    navigate(`/publication-detail/${PublicationId}/${publicationName}`);
    setPublicationId(PublicationId);
    console.log("publicationId123", publicationId);
    console.log("ui_slugui_slugui_slug", ui_slug);
    console.log("publicationDatapublicationData", publicationData);
  };
  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      {Array.isArray(publicationList) && publicationList.length > 0 ? (
        <div className="publication-serach-wrap">
          <div>
            <div className="publication-tabs">
              <div className="publication-tab-wrap">
                {publicationList.map((publication, index) => (
                  <div key={index}>
                    <button
                      className={
                        publications_id?.toString() ===
                        publication.id.toString()
                          ? "active-tab"
                          : ""
                      }
                      onClick={() =>
                        handleButtonClick(
                          publication.name,
                          publication.id,
                          index,
                          publication.ui_slug,
                          publication.albums
                        )
                      }
                    >
                      {publication.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <PublicationSearchModal />
        </div>
      ) : (
        <div></div>
      )}

      <div>
        {" "}
        <Container>
          <div
            className="darshan-details mt-5"
            style={{ display: "flex", gap: "10px" }}
          >
            <p>Book -</p>
            <h6>{name}</h6>
          </div>
          <iframe
            src={pdfUrl}
            style={{
              width: "100%",
              height: "100vh",
              margin: "0 auto",
              display: "flex",
              //   marginTop: "50px",
              marginBottom: "25px",
            }}
          ></iframe>
        </Container>
      </div>
    </>
  );
};

export default Pdf;
