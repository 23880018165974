import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({ donation, handleClickDonation, pages }) => {
  const navigate = useNavigate();

  const handleClickDailyDarshan = () => {
    navigate("/daily-darshan");
  };

  const handleClickDailyKatha = () => {
    navigate("/daily-katha");
  };

  const handlePageClick = async (id, name, website_page, pageUrl) => {
    if (website_page === "other_website") {
      window.open(pageUrl, "_blank");
    } else {
      navigate(`/custome-page/${name}/${id}`);
    }
  };

  console.log("pagespages", pages);

  return (
    <>
      <div className="header-wrap d-flex justify-content-between">
        <div className="d-flex header-left">
          {pages &&
            Object.values(pages).map((page) => (
              <>
                <div>
                  <h6
                    key={page.id}
                    onClick={() =>
                      handlePageClick(
                        page.id,
                        page.name,
                        page.website_page,
                        page.page_url
                      )
                    }
                  >
                    {page.name}
                  </h6>
                </div>
                {/* <div>
                  <h6
                    key={page.id}
                    onClick={() => handlePageClick(page.id, page.name)}
                  >
                    {page.name}2
                  </h6>
                </div>
                <div>
                  <h6
                    key={page.id}
                    onClick={() => handlePageClick(page.id, page.name)}
                  >
                    {page.name}3
                  </h6>
                </div>
                <div>
                  <h6
                    key={page.id}
                    onClick={() => handlePageClick(page.id, page.name)}
                  >
                    {page.name}4
                  </h6>
                </div>
                <div>
                  <h6
                    key={page.id}
                    onClick={() => handlePageClick(page.id, page.name)}
                  >
                    {page.name}5
                  </h6>
                </div> */}
              </>
            ))}
        </div>
        <div className="d-flex header-right">
          <div>
            <h6 onClick={handleClickDailyKatha}>Daily Katha </h6>
          </div>
          <div className={donation !== "1" ? "no-border" : ""}>
            <h6 onClick={handleClickDailyDarshan}>Daily Darshan </h6>
          </div>
          <div>
            {donation === "1" ? (
              <h6 onClick={handleClickDonation}>Donate Now</h6>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
